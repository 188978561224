import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import styled from 'styled-components'


const Separator = styled.div`
  border-color: #fe1d03;
  border-top-width: 4px;
  margin-bottom: 50px;
  width: 100%;
  max-width: 100px;

  border-bottom-style: solid;
  border-top-style: solid;

  height: auto;
  border-bottom-width: 0;
`

const politicaPrivacidad = ({ data }) => {
  return (
    <Layout>
      <SEO
        title="Soluciones"
        keywords={[
          `ember`,
          `apps`,
          `páginas web`,
          `aplicaciones`,
          `consultoria`,
          `soluciones`,
        ]}
      />
      <section className="section">
        <div className="container" style={{ marginTop: '80px' }}>
          <div className="content">

          <div className="columns">
            <div className="column is-three-fifths is-offset-one-fifth">
              <div className="has-text-centered">
                <span className="is-size-1 has-text-weight-bold">
                  Política de Privacidad
                </span>
                <Separator />
                <p className="has-text-justified" style={{ fontSize: '20px', marginTop: '-35px'}}>
                  <strong>Ember</strong> suscribe el presente <strong>Aviso de Privacidad</strong> en cumplimiento a los artículos 15 y 16 de la Ley Federal de Protección de Datos Personales en Posesión de los Particulares (en lo sucesivo la “Ley”).
                </p>
                <p className="has-text-justified" style={{ fontSize: '20px'}}>
                Los datos personales que usted (en lo sucesivo <b>“el Titular”</b>) ha proporcionado directamente o a través de medios electrónicos a Ember (en lo sucesivo <b>“el Responsable”</b>), han sido recabados y serán tratados por el Responsable bajo los principios de licitud, consentimiento, información, calidad, finalidad, lealtad, proporcionalidad y responsabilidad, de conformidad con lo dispuesto por la Ley.
                </p>
                <p className="has-text-justified" style={{ fontSize: '20px'}}>
                El <b>Responsable</b> podrá recabar datos personales sensibles en función del servicio o producto contratado por el <b>Titular</b>.
                </p>
                <p className="has-text-justified" style={{ fontSize: '20px'}}>
                El <b>Responsable</b> hace de su conocimiento que los datos personales proporcionados por el Titular serán utilizados para (i) integrar su expediente como cliente; (ii) prestar los servicios que pudieran llegar a ser o hayan sido contratados; (iii) así como llevar a cabo la adquisición de los derechos o los productos o los servicios relacionados con los mismos.
                </p>
                <p className="has-text-justified" style={{ fontSize: '20px'}}>
                De igual forma, dichos datos personales podrán ser transferidos a terceras personas ya sean físicas o morales, nacionales o extranjeras, con el único propósito de cumplir con los fines señalados en el presente <b>Aviso de Privacidad</b>.
                </p>
                <p className="has-text-justified" style={{ fontSize: '20px'}}>
                Los datos personales proporcionados por el <b>Titular</b> al <b>Responsable</b>, se encontrarán debidamente protegidos a través de medidas de seguridad tecnológica, física y administrativa, previniendo el uso o divulgación indebida de los mismos.
                </p>
                <p className="has-text-justified" style={{ fontSize: '20px'}}>
                El <b>Titular</b> podrá en cualquier momento revocar el consentimiento que ha otorgado al <b>Responsable</b> para el tratamiento de sus datos personales, limitar el uso o divulgación de los mismos, así como ejercer sus derechos de Acceso, Rectificación, Cancelación y Oposición (en lo sucesivo “los <b>Derechos ARCO</b>”) previstos en la Ley, mediante el envío de su solicitud en los términos que marca la Ley en su artículo 29 por medio de un correo electrónico a la dirección electrónica: <a href='mailto:ventas@ember.com.mx'>ventas@ember.com.mx</a> .
                </p>
                <p className="has-text-justified" style={{ fontSize: '20px'}}>
                Importante: cualquier modificación al presente Aviso de Privacidad podrá consultarlo en el sitio <a href='https://ember.com.mx'>ember.com.mx</a> . El presente Aviso de Privacidad podrá ser modificado o actualizado unilateralmente por Ember, informándole al Titular de dichos cambios a través de su página web <a href='https://ember.com.mx'>ember.com.mx</a> .
                </p>
                <p className="has-text-justified" style={{ fontSize: '20px'}}>
                Fecha última actualización: 12/06/2019.
                </p>
              </div>
              
            </div>
          </div>

         
          

            

            

            

            
          </div>
        </div>
      </section>
    </Layout>
  )
}



export default politicaPrivacidad
