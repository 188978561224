/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql, Link } from 'gatsby'
import { FaFacebookF, FaTwitter, FaLinkedinIn } from 'react-icons/fa'
import styled from 'styled-components'

import Header from './header'
// import 'bulma/css/bulma.css'
import './bulma.scss'

const A = styled.a`
  margin: 0 5px;
  font-size: 1rem !important;

  @media (min-width: 992px) {
    font-size: 2rem !important;
    margin: 0 20px;
  }
`
const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Header siteTitle={data.site.siteMetadata.title} />
        <main>{children}</main>
        <footer
          style={{
            height: '100px',
            backgroundColor: '#f4f6f7',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '0 20px',
          }}
        >
          <div>
            © {new Date().getFullYear()}, Ember | Todos los derechos reservados | <Link to="/politicaPrivacidad">Política de privacidad</Link>
          </div>
          <div style={{ display: 'flex' }}>
            <A
              style={{
                color: '#3b5998',
              }}
              href="https://www.facebook.com/emberdev/"
              target="_blank"
              rel="noopener noreferrer"
              alt="facebook ember"
            >
              <FaFacebookF />
            </A>
            <A
              style={{
                color: '#55acee',
              }}
              href="https://twitter.com/ember_code"
              target="_blank"
              rel="noopener noreferrer"
              alt="twitter ember"
            >
              <FaTwitter />
            </A>
            <A
              style={{
                color: '#0077b5',
              }}
              href="https://www.linkedin.com/company/ember-dev/"
              target="_blank"
              rel="noopener noreferrer"
              alt="linkedin ember"
            >
              <FaLinkedinIn />
            </A>
          </div>
        </footer>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
