import { Link, useStaticQuery, graphql } from 'gatsby'
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'
import Responsive from 'react-responsive'
import { slide as Menu } from 'react-burger-menu'
import './header.css'
import { useSpring, animated } from 'react-spring'

// const Mobile = props => <Responsive {...props} maxWidth={991} />
const Desktop = props => <Responsive {...props} minWidth={992} />

const Sticky = styled.div`
  position: ${props => (props.active ? 'fixed' : 'relative')};
  top: ${props => (props.active ? '0' : 'unset')};
  z-index: 10;
  transform: translate3d(0px, 0px, 0px);
  width: 100%;
`

const Nav = styled.nav`
  /* .saas_navbar */
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  transition: all 0.35s ease-in-out 0s;
  /* padding: 30px 0px; */

  -webkit-box-align: center;
  align-items: center;
  min-height: 56px;
  display: block;

  /* .saas_navbar_active */
  box-shadow: ${props =>
    props.active ? 'rgba(43, 83, 135, 0.08) 0px 3px 8px 0px' : 'unset'};

  background: ${props => (props.active ? 'rgb(255, 255, 255)' : 'unset')};
  padding: ${props => (props.active ? '5px 0px' : '30px 0px')};
`

const BurgerMenu = styled.div`
  @media (min-width: 992px) {
    display: none;
  }
`

const Header = ({ data }) => {
  const [scrollY, setScrollY] = useState(0)

  const logoWidth = useSpring({ width: scrollY > 0 ? '50px' : '80px' })

  const listenToScroll = () => {
    setScrollY(window.pageYOffset)
  }

  useEffect(() => {
    window.addEventListener('scroll', listenToScroll)
    return () => {
      window.removeEventListener('scroll', listenToScroll)
    }
  }, [])

  const { logo } = useStaticQuery(
    graphql`
      query {
        logo: file(relativePath: { eq: "logo_ember.png" }) {
          childImageSharp {
            fluid(maxWidth: 80) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )

  return (
    <>
      {/* <Mobile> */}
      <BurgerMenu>
        <Menu right disableAutoFocus>
          <Link
            style={{ color: '#4a4a4a' }}
            className="is-size-4"
            activeStyle={{ color: '#fe1d03' }}
            to="/"
          >
            <strong>Inicio</strong>
          </Link>
          <Link
            style={{ color: '#4a4a4a' }}
            className="is-size-4"
            activeStyle={{ color: '#fe1d03' }}
            to="/nosotros"
          >
            <strong>Nosotros</strong>
          </Link>
          <Link
            style={{ color: '#4a4a4a' }}
            className="is-size-4"
            activeStyle={{ color: '#fe1d03' }}
            to="/soluciones"
          >
            <strong>Soluciones</strong>
          </Link>
          <Link
            style={{ color: '#4a4a4a' }}
            className="is-size-4"
            activeStyle={{ color: '#fe1d03' }}
            to="/contacto"
          >
            <strong>Contacto</strong>
          </Link>
        </Menu>
      </BurgerMenu>
      {/* </Mobile> */}
      <Sticky active={scrollY > 0}>
        <Nav active={scrollY > 0}>
          <div
            style={{
              marginLeft: 'auto',
              marginRight: 'auto',
              paddingLeft: '30px',
              paddingRight: '30px',
            }}
          >
            <div
              style={{
                boxSizing: 'border-box',
                width: '100%',
                display: 'flex',
                WebkitBoxAlign: 'center',
                alignItems: 'center',
                WebkitBoxPack: 'justify',
                justifyContent: 'space-between',
              }}
            >
              <animated.div style={logoWidth}>
                <Link to="/">
                  <Img fluid={logo.childImageSharp.fluid} />
                </Link>
              </animated.div>
              <Desktop>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div className="navbar-item">
                    <Link
                      style={{ color: '#4a4a4a' }}
                      activeStyle={{ color: '#fe1d03' }}
                      to="/"
                    >
                      <strong>Inicio</strong>
                    </Link>
                  </div>

                  <div className="navbar-item">
                    <Link
                      style={{ color: '#4a4a4a' }}
                      activeStyle={{ color: '#fe1d03' }}
                      to="/nosotros"
                    >
                      <strong>Nosotros</strong>
                    </Link>
                  </div>

                  <div className="navbar-item">
                    <Link
                      style={{ color: '#4a4a4a' }}
                      activeStyle={{ color: '#fe1d03' }}
                      to="/soluciones"
                    >
                      <strong>Soluciones</strong>
                    </Link>
                  </div>

                  <div className="navbar-item">
                    <div className="buttons">
                      <Link
                        className="button is-primary is-rounded"
                        to="/contacto"
                      >
                        <strong>Contacto</strong>
                      </Link>
                    </div>
                  </div>
                </div>
              </Desktop>
            </div>
          </div>
        </Nav>
      </Sticky>
    </>
  )
}

// Header.propTypes = {
//   siteTitle: PropTypes.string,
// }

// Header.defaultProps = {
//   siteTitle: ``,
// }

export default Header
